<template>
  <v-col
    cols="12"
    :md="size === 2 ? 6 : size === 3 ? 4 : undefined"
  >
    <v-card
      :color="order%2===1 ? 'grey lighten-3':'grey lighten-2'"
    >

      <v-card-title class="text-h5">
        Participant ID: {{ value.participantID.value }}
        <strong v-if="value.entities.length>20"> (20 entities)</strong>
        <strong v-if="value.entities.length>1 && value.entities.length<21">&nbsp; ({{ value.entities.length }} entities)
        </strong>
      </v-card-title>

      <v-card-text>

        <div
          v-for="(entity,index) in value.entities"
          class="text-left ma-0"
          :key=index
          v-if="index<20"
        >
          <v-col cols="12">
            <v-row class="pa-0" align="center">
              <v-col cols="3" class="pa-0"><strong>Country : </strong></v-col>
              <v-col cols="1" class="pa-0">
                <country-flag :country=entity.countryCode.toLowerCase() al size="normal"/>
              </v-col>
              <v-col cols class="pa-0">{{ country(entity.countryCode) }} ({{ entity.countryCode }})
              </v-col>
            </v-row>
            <v-row class="pa-0" align="center">
              <v-col cols="3" class="pa-0"><strong>Entity Name: </strong></v-col>
              <v-col cols="9" class="pa-0">{{ entity.name[0].name }}</v-col>
            </v-row>
            <v-row class="pa-0" v-if="entity.geoInfo" align="center">
              <v-col cols="3" class="pa-0"><strong>Geographical information: </strong></v-col>
              <v-col cols="9" class="pa-0">{{ entity.geoInfo }}</v-col>
            </v-row>
            <v-row class="pa-0" v-if="entity.additionalInfo" align="center">
              <v-col cols="3" class="pa-0"><strong>Additional information: </strong></v-col>
              <v-col cols="9" class="pa-0">{{ entity.additionalInfo }}</v-col>
            </v-row>
            <v-row class="pa-0" justify="end">
              <template>
                <div>
                  <v-dialog
                    v-model="dialog"
                    width="80%"

                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        text-color="white"
                        label
                        color="#4caf50"
                        v-bind="attrs"
                        v-on="on"
                        @click.stop=""
                        @keydown.esc="$emit('input')"
                      >
                        Show Detail
                        <v-avatar>
                          <v-icon>mdi-magnify</v-icon>
                        </v-avatar>
                      </v-chip>
                    </template>

                    <v-card>
                      <v-toolbar
                        color="dark"
                        flat
                        gray
                      >
                        <v-toolbar-title><b>Participant ID: {{ value.participantID.value }}</b>
                        </v-toolbar-title>
                        <template v-slot:extension>
                          <v-tabs
                            v-model="tab"
                          >
                            <v-tabs-slider></v-tabs-slider>

                            <v-tab href="#information">
                              <p class="font-weight-bold">
                                Business Information ({{ value.entities.length }})
                              </p>
                            </v-tab>
                            <v-tab href="#doctypes">
                              <p class="font-weight-bold">
                                Supported Document Types ({{ value.docTypes.length }})
                              </p>
                            </v-tab>
                          </v-tabs>
                        </template>
                      </v-toolbar>
                      <v-divider></v-divider>
                      <v-tabs-items v-model="tab">
                        <v-tab-item :key="1" value="information">
                          <v-col cols="12">
                            <v-card class="text-sm-body-2 fill-height" v-for="(entity,index) in value.entities" flat
                                    :key=index>
                              <v-col class=" fill-height">
                                <v-row class="pa-0" v-if="value.entities[index].countryCode" align="center">
                                  <v-col cols="3" class="pa-0"><strong>Country :</strong></v-col>
                                  <v-col cols="1" class="pa-0">
                                    <country-flag :country=value.entities[index].countryCode.toLowerCase() al
                                                  size="normal"/>
                                  </v-col>
                                  <v-col class="pa-0"> {{ country(value.entities[index].countryCode) }}
                                    ({{ value.entities[index].countryCode }})
                                  </v-col>
                                </v-row>
                                <v-row v-if="value.entities[index].name" align="center" class="pa-0">
                                  <v-col cols="3" class="pa-0"><strong>Entity Name:</strong></v-col>
                                  <v-col cols="9" class="pa-0">{{ value.entities[index].name[0].name }}
                                    <li v-if=" value.entities[index].name[0].language">({{
                                        value.entities[index].name[0].language
                                      }})
                                    </li>
                                  </v-col>
                                </v-row>
                                <v-row class="pa-0" v-if="value.entities[index].geoInfo" align="center">
                                  <v-col cols="3" class="pa-0"><strong>Geographical Information:</strong></v-col>
                                  <v-col cols="9" class="pa-0">{{ value.entities[index].geoInfo }}</v-col>
                                </v-row>
                                <v-row class="pa-0" v-if="value.entities[index].additionalInfo" align="center">
                                  <v-col cols="3" class="pa-0"><strong>Additional Information:</strong></v-col>
                                  <v-col cols="9" class="pa-0">{{ value.entities[index].additionalInfo }}</v-col>
                                </v-row>
                                <v-row class="pa-0" v-if="value.entities[index].websites">
                                  <v-col cols="3" class="pa-0"><strong>Web Site :</strong></v-col>
                                  <v-col cols="9" class="pa-0">
                                    <v-simple-table class="mytable">
                                      <template v-slot:default>
                                        <tbody>
                                        <tr v-for="(website,indexWebsite) in value.entities[index].websites"
                                            v-bind:key="indexWebsite">
                                          <td><a v-bind:href="website">{{ website }}</a></td>
                                        </tr>
                                        </tbody>
                                      </template>
                                    </v-simple-table>
                                  </v-col>
                                </v-row>
                                <v-row class="pa-0" v-if="value.entities[index].contacts">
                                  <v-col cols="3" class="pa-0"><strong>Contacts:</strong></v-col>
                                  <v-col cols="9" class="pa-0">
                                    <v-simple-table>
                                      <template v-slot:default>
                                        <thead>
                                        <tr>
                                          <th class="text-left">
                                            <strong>Type</strong>
                                          </th>
                                          <th class="text-left">
                                            <strong>Name</strong>
                                          </th>
                                          <th class="text-left">
                                            <strong>Phone Number</strong>
                                          </th>
                                          <th class="text-left">
                                            <strong>Email</strong>
                                          </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="contact in value.entities[0].contacts">
                                          <td>{{ contact.type }}</td>
                                          <td>{{ contact.name }}</td>
                                          <td>{{ contact.phone }}</td>
                                          <td>{{ contact.email }}</td>
                                        </tr>
                                        </tbody>
                                      </template>
                                    </v-simple-table>
                                  </v-col>
                                </v-row>
                                <v-row class="pa-0" v-if="value.entities[index].regDate" align="center">
                                  <v-col cols="3" class="pa-0"><strong>Full Peppol Participant Id:</strong></v-col>
                                  <v-col cols="9" class="pa-0" style="color: #e83e8c;font-family:Menlo">{{
                                      value.participantID.scheme
                                    }}::{{
                                      value.participantID.value
                                    }}
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-divider v-if="index!==value.entities.length-1"></v-divider>
                            </v-card>

                          </v-col>
                        </v-tab-item>
                        <v-tab-item :key="2" value="doctypes">
                          <v-col cols="12">
                            <v-card class="text-sm-body-2" flat>
                              <v-col>
                                <v-row
                                  v-for="(item,i) in value.docTypes"
                                  :key="item.value"
                                >
                                  <v-col class="pa-0" cols="1"> {{ i + 1 }}.</v-col>
                                  <v-col class="pa-0" cols="11">
                                    <span v-if="get_supported_doc_type_name(item)" class="badge-success badge">{{
                                        get_supported_doc_type_name(item).name
                                      }}</span> &nbsp;
                                    <span
                                      v-if="get_supported_doc_type_name(item) && get_supported_doc_type_name(item).deprecated"
                                      class="badge badge-warning badge">
                                      Identifier is deprecated
                                    </span>
                                    <small
                                      style="color: #e83e8c;font-family:SFMono-Regular,Menlo,Monaco,Consolas,'Liberation Mono','Courier New',monospace">({{
                                        item.scheme
                                      }}::{{
                                        item.value
                                      }})
                                    </small>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-card>
                          </v-col>
                        </v-tab-item>
                      </v-tabs-items>
                    </v-card>
                  </v-dialog>
                </div>
              </template>

            </v-row>
          </v-col>
          <v-divider v-if=" index<value.entities.length-1 && index<19"></v-divider>
        </div>

      </v-card-text>

    </v-card>

  </v-col>
</template>

<script>

import {mapGetters} from "vuex";

const countries = require('i18n-iso-countries');
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

export default {
  name: 'FeedCard',
  data: () => ({
    tab: 'information',
    dialog: false,
    countries: {}
  }),
  methods: {
    lowerCase(str) {
      return str.toLowerCase();
    },
    country(code) {
      return countries.getNames('en', {select: 'official'})[code];
    },
    get_supported_doc_type_name(item) {
      if (this.docTypeIdMapping.hasOwnProperty(item.scheme + '::' + item.value))
        return this.docTypeIdMapping[item.scheme + '::' + item.value]
      else
        return null
    }
  },
  props: {
    size: {
      type: Number,
      required: true
    },
    //TODO businesscard entity distinct by name or other field
    value: {
      type: Object,
      default: () => ({})
    },
    order: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters(['docTypeIdMapping']),
  },
};
</script>

<style>
.v-image__image {
  transition: .3s linear;
}

.mytable table td {
  border-bottom: none !important;
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.badge-warning {
  color: #fff;
  background-color: #ffc107;
}

</style>
